<template>
  <div class="main">
    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>我的培训</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>

      <div class="content-free">
        <div class="content">
          <div class="tabs">
            <div
              class="tabItem"
              v-for="(item, index) in tabs"
              :key="index"
              @click="chooseTab(item, index)"
              :class="tabActive == index ? 'tabActive' : ''"
            >
              {{ item }}
            </div>
          </div>
        </div>

<!--         <div class="free">
          <el-radio v-model="radio" label="1">免费</el-radio>
          <el-radio v-model="radio" label="2">付费</el-radio>
        </div>
 -->
      </div>

      <div class="jobInfoBox">
        <div
          class="jobInfoItem"
          v-for="(item, index) in timetableList"
          :key="index"
          @click.stop="toTrainingDetails(item.id)"
        >
          <div class="jobInfoItemTop">
            <img
              class="photo"
              :src="$imgUrl(item.litimg)"
              :onerror="$global.srcImgUrl"
              alt=""
            />
            <div class="personInfo">
              <div class="personInfoLeft">
                <div class="personInfo-f14">
                <span class="personInfo-title">{{ item.title }}</span>
                <!-- <span class="personInfpRight-count">{{item.discount_rate}}</span> -->
              </div>

                <span class="personInfo-sub">{{ item.sub_title }}</span>

                <div class="personInfo-bottom">
                  <span class="personInfo-time">开始时间&nbsp;&nbsp;:&nbsp;&nbsp;{{getPartTime(item.create_time)}}</span>
<!--                   <el-button class="personInfo-but">
                    <span style="color:#fff">开始学习</span></el-button> -->
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>



    </div>

    <div class="pagination">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="chengePagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { trainingApplyPage } from "@/api/personalCenter/personalCenter";
export default {
  data() {
    return {
      /*       tabs: ["全部", "已完成", "未完成"], */
      tabs: ["已报名"],
      tabIndex: 0,
      total: 1,
      status: [],
      tabActive: 0,
      id:'',
      multipleSelection: "",
      timetableList: [],


      imgUrl: "",

      radio: "1",

      params: {
        pageNo: 1,
        pageSize: 10,
        jobUserId:localStorage.getItem("userId"),
      },


    };
  },

  created() {
    this.getTimetable();
    this.params.jobUserId  =  localStorage.getItem("userId");
  },
  mounted() {},

  methods: {
    chooseTab(item, index) {
      this.tabActive = index;
      console.log(item,this.tabIndex,index)
      if (item == "全部" && this.tabIndex == index) {
        this.params.status = "";
        this.getTimetable();
      }
      if (item == "已完成" && this.tabIndex != index) {
        this.params.status = 1;
        this.getTimetable();
      }
      if (item == "未完成" && this.tabIndex != index) {
        this.params.status = 2;
        this.getTimetable();
      }
    },

    imgInfo(code) {
      return this.imgUrl + code;
    },

    getPartTime(val) {
      var timearr = val.replace(" ", ":").replace(/:/g, "-").split("-");
      var timestr =
        "" + Number(timearr[0]) + "-" + timearr[1] + "-" + timearr[2];
      return timestr;
    },

    getTimetable() {
      trainingApplyPage(this.params).then((res) => {
        console.log(res, "培训");
        this.timetableList = res.data.rows;
        this.total = res.data.totalRows;
      });


    },

    //培训详情
    toTrainingDetails(id, held) {
      this.$router.push({
          path: "/registrationDetails",
          query: { id: id, held: held }
      })
    },

     // 翻页
     chengePagination(cur) {
      const obj  =  {
        jobUserId: localStorage.getItem('userId'),
        pageNo: cur,
        pageSize: 10,
      }
      trainingApplyPage(obj).then((res) => {
        console.log(res,obj,"我的培训")
        this.tableData = res.data.rows;
        console.log(this.tableData,"培训")
        this.total = res.data.totalRows;
      });
    },

    handleClick(tab, event) {
      console.log(tab, event)
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

  },
};
</script>

<style scoped lang="scss">
.gray {
  color: #999;
}

.pagination {
  text-align: center;
  margin-top: 20px;
}

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.content-free {
  display: flex;
  justify-content: space-between;
  .content {
    padding: 0 45px;
    .tabs {
      display: flex;
      margin-bottom: 30px;
      .tabItem {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        color: #999;
        padding: 0 20px;
        margin-right: 10px;
        cursor: pointer;
      }
      .tabItem:hover {
        text-decoration: underline;
      }
    }

    .tabActive {
      background-color: #36aeff;
      color: #fff !important;
      border-radius: 4px;
    }
  }
  .free {
    margin-right: 50px;
  }
}

.jobInfoBox {
  padding: 0 48px;
  padding-bottom: 70px;
  .jobInfoItem {
    padding: 15px 0 12px 0;
    border-bottom: 1px solid #ccc;
    border: 1px solid #f2f2f2f2;
    margin-bottom: 20px;

    :hover{
        background-color: #eef;
      }
    .jobInfoItemTop {
      display: flex;
      margin-bottom: 10px;

      .photo {
        width: 180px;
        // height: 100px;
        object-fit: cover;
        margin-right: 14px;
        margin-left: 30px;
        padding-top:10px;
      }
      .personInfo {
        flex: 1;
        display: flex;
        justify-content: space-between;
        .personInfoLeft {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .personInfo-title {
          font-size: 18px;
          font-weight: bold;
          color: #000;
          padding: 10px  18px;
        }
        .personInfo-f14  {
          display: flex;
          justify-content: space-between;
        }
        .personInfo-sub {
          font-size: 14px;
          //  margin-top: -40px;
          color: #666;
          padding-left:18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .personInfo-bottom {
          display: flex;
          justify-content: space-between;
          .personInfo-time  {
            color: #999;
           margin-top: 50px;
           padding-left: 18px;
          }
          .personInfo-but  {
            position: relative;
            right: -20px;
            width: 140px;
           background: #029AFFFF;
           margin-top: 30px;
           margin-right: 30px;
          }
        }

            .personInfpRight-count  {
              color: red;
              font-size: 36px;
              margin-right: 70px;
        }
      }
    }
  }
}
</style>
